@import "../../../../responsive";

.slider-part {
  flex: 2;
}
.detail-part {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
  @include respond-to(mid-screens) {
    padding-top: 3rem;
    padding-left: 0em;
  }
}
.item {
  background: transparent;
  width: 100%;
  height: auto;
  z-index: 10;
  transition: transform 300ms ease 100ms;
  display: flex;
  overflow: hidden;
  object-fit: fit;

  div img {
    width: 100%;
    height: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    cursor: pointer;
    @include respond-to(wide-screens) {
      max-height: 62vh !important;
      object-fit: cover;
    }
  }
}

.player-main {
  width: 94.5%;
  min-height: 62vh;
  margin: 0 auto;
  @include respond-to(mid-screens) {
    min-height: unset;
    height: 24.0583125em;
  }
  @include respond-to(handhelds) {
    min-height: unset;
    height: 15.2em;
  }
  .player-ours {
    width: 100%;
    height: 100%;
    background-color: rgb(240, 240, 240) !important;
    #y-player {
      height: 100% !important;
      width: 100% !important;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.player-main {
  margin-top: 10px;
}
.item.active {
  display: inline;
}

.our-player-play-pause {
  display: inline-flex;
  position: absolute;
}

.slider-btn-main {
  width: 95%;
  margin: 0 auto;
  position: relative;
  .slider-buttons {
    display: inline-flex;
    margin-top: 5px;
    position: absolute;
    right: -0.5rem;
    width: 7.1em;
    height: 2em;
    button:disabled,
    button[disabled] {
      color: rgb(187, 187, 187) !important;
    }
    .number-text {
      font-size: 1rem;
      margin-top: 0.26em;
    }
  }
}

.play {
  background-color: #fff;
  font-size: 23px;
  border-style: solid;
  border-width: 0px;
  &:hover {
    border-color: transparent transparent transparent #241c1c86;
  }
  background: transparent;
  cursor: pointer;
  margin-top: 5px;
}

.pause {
  margin-top: 16px;
  margin-right: -11px;
  height: 16px;
  border-style: double;
  border-width: 0px 0px 0px 15px;
  border-color: #202020;
  cursor: pointer;
  background: transparent;
  &:hover {
    border-color: transparent transparent transparent #241c1c86;
  }
}

a {
  padding: 0px 0px;
  // margin-top: 5px;
  font-family: "CaviaDreams";
  // color: #131313;
}

.previous {
  color: black;
  background: transparent;
  font-size: 40px;
  border-width: 0px;
  border: 0px;
  margin-top: -20px;
}

.next {
  background: transparent;
  color: black;
  font-size: 40px;
  border-width: 0px;
  margin-top: -20px;
}

.view-btn {
  border: none;
  margin-top: 20px;
  width: 100px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  margin-bottom: 20px;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.slider-image {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  @include respond-to(mid-screens) {
    margin-left: 0px;
  }
  img {
    width: 94.1% !important;
    height: 100%;
  }
}

.play:focus {
  outline: 0;
}
.pause:focus {
  outline: 0;
}
.previous:focus {
  outline: 0;
}
.next:focus {
  outline: 0;
}
