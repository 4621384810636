* {
  outline: none;
}

.App {
  height: 100vh;
  cursor: url(cursor.png), auto;
  width: 100%;
  align-items: stretch;
}

#main-canvas {
  z-index: -1;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "CaviaDreams";
  src: local("CaviaDreams"), url(./fonts/CAVIARDREAMS.TTF) format("truetype");
}

@font-face {
  font-family: "CaviaDreamsBold";
  font-weight: 900;
  src: local("CaviaDreams"),
    url(./fonts/CAVIARDREAMS_BOLD.TTF) format("truetype");
}

@font-face {
  font-family: "CaviaDreamsItalic";
  font-weight: 900;
  src: local("CaviaDreams"),
    url(./fonts/CAVIARDREAMS_ITALIC.TTF) format("truetype");
}
@font-face {
  font-family: "CaviaDreamsBoldItalic";
  font-weight: 900;
  src: local("CaviaDreams"),
    url(./fonts/CAVIARDREAMS_BOLDITALIC.TTF) format("truetype");
}
