@import "../../responsive";

body {
  overflow-x: hidden;
}
.footer {
  font-family: "CaviaDreams";
  min-width: 18em;
  color: #6c757d;
  text-align: right;
  position: fixed;
  bottom: 1.3%;
  right: 3.5%;
  display: flex;
  grid-gap: 5px;
  .message {
    width: 90%;
    font-size: 0.9rem;
    color: #fff;
    position: relative;
    .tooltip-1 {
      width: 100%;
      height: 100%;
      padding-top: 0.8em;
      background-color: rgb(37, 37, 37);
      padding-right: 1em;
      position: absolute;
      transition: 2s;
    }
    .tooltip-2 {
      background-color: rgb(37, 37, 37);
      width: 100%;
      height: 100%;
      padding-top: 0.8em;
      padding-right: 1em;
      position: absolute;
      transition: 2s;
    }
  }
  .cr-logo {
    width: 10%;
    font-size: 2rem;
    cursor: pointer;
  }
}

.tooltip-inner {
  max-width: 100% !important;
}
@media all and (max-width: 1356px) {
  .footer {
    display: none;
  }
}
