@import "../../../../responsive";

.team-content {
  min-height: 580px;
  width: 70%;
  margin: 0 auto;
  display: grid;
  place-items: center;

  .members-collection {
    width: 95%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 3fr)); /*width*/
    grid-auto-rows: minmax(350px, auto); /*height*/

    #member {
      display: flex;
      flex-direction: column;
      #member-image {
        flex: 1;
        display: grid;
        place-items: center;
        #img {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          box-shadow: 0px 0px 12px 3px rgb(238, 238, 238);
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            -webkit-filter: grayscale(100%); // Safari 6.0 - 9.0 /
            filter: grayscale(100%);
          }
        }
      }
      #member-information {
        flex: 1;
        padding: 0px 20px 0px 20px;
        text-align: center;
        font-size: 15px;
        #icon-p {
          padding-top: 10px;
        }
        p {
          font-family: "CaviaDreams";
          margin-bottom: 5px !important;

          span {
            font-family: "CaviaDreamsBold";
            font-weight: bold;
          }
          #icon {
            padding: 10px 5px 0 5px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}

.sample-img {
  width: 70%;
  margin: 50px auto;
  display: grid;
  place-items: center;
  @include respond-to(mid-screens) {
    margin: 80px auto;
  }

  img {
    width: 70%;
    height: auto;
    @include respond-to(mid-screens) {
      width: 95%;
    }
    @include respond-to(handhelds) {
      width: 95%;
    }
  }
  @include respond-to(mid-screens) {
    width: 95%;
  }
  @include respond-to(handhelds) {
    width: 95%;
  }
  
}
