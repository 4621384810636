@import "../../responsive";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");

.company-logo {
  min-height: 80px;
  width: 100vw;
  margin-bottom: 10px;
  img {
    margin-top: 20px;
    margin-left: 3.4em;
    width: 195px;
    height: 70px;
    @include respond-to(mid-screens) {
      margin-left: 3.4em;
      width: 150px;
      height: 60px;
    }
    @include respond-to(handhelds) {
      margin-left: 2em;
    }
  }
}

.toogle-company {
  display: inline-block;
  position: absolute;
  @include respond-to(mid-screens) {
    right: 0%;
    top: 5%;
    margin-right: 3.4em;
  }
  @include respond-to(handhelds) {
    margin-right: 2em;
  }
  a {
    display: flex;
    flex-direction: row;
    @include respond-to(mid-screens) {
      flex-direction: row-reverse;
    }
  }
}
.toogle-text {
  padding-top: 3px;
  color: #6c757d;
  font-family: "CaviaDreams";
  font-size: 13px;
  padding-left: 10px;
}

.toogle-bar {
  background: #6c757d;
  height: 25px;
  width: 1.5px;
  margin-left: 3.4em;
  @include respond-to(mid-screens) {
    margin-left: 1em;
  }
}
