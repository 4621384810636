@import "../../../responsive";

#sub-menu {
  cursor: pointer;
}

#transit {
  transition: 2s;
}

.menu-main {
  position: fixed;
  bottom: 1.1%;
  left: 3.4%;
  z-index: 999;
  background-color: #fff;
}

.menu {
  z-index: 99;
  color: rgb(107, 107, 107) !important;
  display: flex;
  flex-direction: row;
  background-color: #fff;

  a {
    color: rgb(3, 3, 3) !important;
  }
}

.menu-active {
  color: #000;
  font-weight: 900;
}

.about-menu {
  display: inline-block;
  padding-left: 10px;
  padding-bottom: 1px;
  background-color: #fff;
  transition: 2s;
}
.list {
  transition: 0.3s;
  a {
    display: block;
    padding-bottom: 1px;
  }
}

.ham-menu {
  position: relative;
  width: 50px;
  @include respond-to(mid-screens) {
    margin-left: 1.8em;
  }

  button {
    position: absolute;
    bottom: 1%;
    margin-left: 0px !important;
  }
}
#main-menu {
  font-size: 1.4rem;
}

.contact-menu {
  text-align: right;
  font-family: "CaviaDreams";
  transition: 0.1s;
  transform-origin: left;
  background-color: #fff;
}

.menu-item {
  display: inline-grid;
  text-align: right;
  font-family: "CaviaDreams";
  font-size: 1.6em;
  z-index: 1;
  opacity: 1;
  background: #fff;
  padding-bottom: 0.19em;
}

.full-slider {
  position: fixed;
  display: inline-flex;
  margin-top: -30px;
}

.about-menu {
  font-family: "CaviaDreams";
  transition: 0.2s;
}

.work-menu {
  transition: 0.2s;
  padding-bottom: 5px;
  a {
    display: block;
    border: none !important;
    transition: 0.2s;
  }
}

.burger_hamburger-inner__14XT2 {
  border-radius: 0px !important;
  width: 27px !important;
  height: 3.5px !important;
}

.burger_hamburger-inner__14XT2::before {
  content: "";
  width: 27px !important;
  height: 3.5px !important;
  border-radius: 0px !important;
}

.location {
  text-transform: uppercase;
  font-family: "CaviaDreamsBold";
  font-size: 1.5rem;
  padding-bottom: 0.05em;
  @include respond-to(mid-screens) {
    padding-top: 0.3em;
  }
}

.submenu {
  font-family: "CaviaDreams";
  font-size: 1rem;
  padding-top: 0.3em;
  padding-left: 0.8rem;
  text-transform: capitalize;
  @include respond-to(mid-screens) {
    padding-top: 0.7em;
  }
}
@media all and (min-width: 1696px) {
  .burger_hamburger-inner__14XT2::before {
    height: 3.5px !important;
  }
}

.burger_hamburger-inner__14XT2::after {
  content: "";
  width: 27px !important;
  height: 3.5px !important;
  border-radius: 0px !important;
}

.copyright {
  display: none;
  cursor: pointer;
}

@media all and (max-width: $break-large) {
  .menu-main {
    width: 100%;
    min-height: 45px;
    bottom: 0;
    left: 0;
    box-shadow: 0px 0px 6px 1px rgb(206, 206, 206);
    z-index: 9999;
  }

  .menu {
    width: 100%;
    min-height: 45px;
    position: relative;
  }
  .copyright {
    display: block;
    font-size: 1.7rem;
    position: absolute;
    right: 4%;
    bottom: 3%;
  }

  .about-menu {
    padding: 0;
    transition: 0.2s !important;
    a {
      padding-bottom: 5px;
    }
  }

  .menu-item {
    width: 100%;
    text-align: center;
    padding-right: 70px;
  }

  .about-menu {
    text-align: center;
    width: 100%;
    padding-left: 0.8em;
  }

  .work-menu {
    text-align: center;
    width: 100%;
    padding-left: 0.8em;
  }
}

#plus-minus {
  font-size: 2rem;
}
