@import "../../../responsive";

.home-wrapper {
  width: 95%;
  margin: 0 auto;
  @include respond-to(handhelds) {
    width: 100%;
  }
  @include respond-to(xwide-screens) {
    width: 95%;
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.root-content {
  width: 100%;
  margin: 0px auto;
  display: flex;
  @include respond-to(mid-screens) {
    flex-direction: column-reverse;
    width: 90%;
  }
  @include respond-to(xwide-screens) {
    width: 100%;
  }

  .company-content {
    @include respond-to(mid-screens) {
      margin-bottom: 15px;
      padding: 0px 20px 0px 20px;
      min-height: 150px;
    }
    .title {
      text-align: right;
      padding-top: 20px;
      padding-right: 5px;
      font-size: 1.4rem;
      font-family: "CaviaDreamsBold";
      font-weight: bolder;
      @include respond-to(mid-screens) {
        text-align: center;
        font-size: 17px;
      }
    }
    .description {
      text-align: right;
      padding-top: 14px;
      padding-right: 5px;
      font-size: 0.8rem;
      font-family: "CaviaDreams";
      opacity: 0.8;
      @include respond-to(mid-screens) {
        text-align: center;
        font-size: 0.8rem;
      }
    }
  }
}
.filler {
  width: 100%;
  height: 39px;
}
