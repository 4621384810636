@import "../../../../responsive";

#introduction {
  min-height: 500px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  @include respond-to(mid-screens) {
    min-height: 500px;
  }
}

.introduction-content {
  background: transparent;
  height: 80%;
  width: 75%;
  @include respond-to(mid-screens) {
    width: 90%;
  }

  .intro-description {
    font-size: 0.9rem;
    font-family: "CaviaDreams";
    text-align: left;
    padding-top: 0.7em;
    opacity: 0.6;
    padding-bottom: 3em;
  }
}

.title {
  font-size: 1.3rem;
  font-family: "CaviaDreamsBold";
  @include respond-to(handhelds) {
    text-align: center;
  }
}

.mission {
  font-size: 1.3rem;
  font-family: "CaviaDreamsBold";
  @include respond-to(handhelds) {
    text-align: center;
  }
}
