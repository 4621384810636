body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'CaviaDreams', 'CaviaDreamsBold',
    'CaviaDreamsItalic','CaviaDreamsBoldItalic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'CaviaDreams','CaviaDreamsBold',
  'CaviaDreamsItalic','CaviaDreamsBoldItalic';
}

html, body, #app, #app>div {
  height: 100vh;
}