@import "../../../responsive";

.contact-main {
  width: 95%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  @include respond-to(mid-screens) {
    flex-direction: column-reverse;
  }

  .right-page {
    display: inline-block;
    flex: 2;
    iframe {
      width: 97%;
      height: 75vh;
      border: 0;
      -webkit-filter: grayscale(100%); // Safari 6.0 - 9.0 /
      filter: grayscale(100%);
      @include respond-to(mid-screens) {
        height: 400px;
        border: 0;
      }
    }
    @include respond-to(mid-screens) {
      display: inline-block;
      margin-top: 40px;
      flex: 2;
    }
  }

  .left-page {
    padding: 10px 20px 10px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    @include respond-to(mid-screens) {
      flex-direction: column-reverse;
    }

    .contact-form {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      form {
        display: inline-block;
        height: 75%;
        width: 100%;
        @include respond-to(mid-screens) {
          margin-top: 30px;
          margin-bottom: 40px;
        }
        input {
          width: 100% !important;
        }
      }
    }
    .contact-text {
      flex: 1;
      padding-top: 30px;
    }

    #icon {
      padding-left: 8px;
    }
    .md-form {
      margin-bottom: 0em !important;
      margin-top: 0em !important;
      font-family: "CaviaDreams" !important;
      textarea.md-textarea {
        padding: 0em !important;
      }
    }
  }

  .button-color {
    background-color: black !important;
    color: #fff !important;
    float: right;
    font-family: "CaviaDreams";
    width: 7em;
    height: 35px;
    margin: 0;
    font-size: 0.8em !important;
    border-radius: 0em !important;
  }

  .contact-text {
    font-family: "CaviaDreams";
    clear: both;
    text-align: right;
    font-size: 0.9em;
    @include respond-to(mid-screens) {
      text-align: center;
    }
  }
}
